.assessment-progress-scope() {
  @progress-bar-border-width: .2rem;
  @marker-border-width: .2rem;

  @overlap-offset: 0.1rem;

  @marker-size: @ax-spacing-m;
  @marker-bg-size: @marker-size + (@marker-border-width * 2);

  @progress-section-split-width: ~'calc((100% - 3 * @{marker-bg-size}) / 2 - 2 * @{overlap-offset})';
  @progress-section-full-width: ~'calc(100% - 2 * @{marker-bg-size} - 2 * @{overlap-offset})';

  @progress-bar-height: @ax-spacing-s;
  @progress-bar-radius: @progress-bar-height / 2;

  @progress-bar-index: 1;
  @background-index: 2;
  @midground-index: 3;
  @foreground-index: 4;

  .assessment-progress-bar {
    font-size: @ax-font-size-xs;
    padding-top: 0.5em;

    .screen-md-portrait-min({
      padding-top: 0;
    });

    .progress-marker {
      .relative;
      .pull-left;
      margin-top: @ax-spacing-m;
      width: @marker-bg-size;
      height: @marker-bg-size;

      .marker-layer {
        .absolute;
        .border-radius(50%);
        top: 0;
        left: 0;
        text-align: center;

        &.background {
          .box-shadow(0 .1rem .4rem rgba(0,0,0,0.32));
          z-index: @background-index;
          background-color: @white;
          width: @marker-bg-size;
          height: @marker-bg-size;
        }

        &.midground {
          top: @marker-border-width;
          left: @marker-border-width;
          z-index: @midground-index;
          background-color: @white!important;
          width: @marker-size;
          height: @marker-size;

          &.active {
            background-color: @primary-blue!important;
          }

          &.failed {
            background-color: @red!important;
          }
        }

        &.foreground {
          z-index: @foreground-index;
          top: @marker-border-width;
          left: @marker-border-width;

          font-size: 1.3rem;
          line-height: @marker-size;
          color: @white;

          width: @marker-size;
          height: @marker-size;

          &.icon-play {
            font-size: @marker-size / 2;
            .rtl-only({
              right: 0rem !important;
            });

            &:before {
              margin-left: .2rem;
            }
          }
        }
      }
    }

    .progress-section {
      .pull-left;
      .relative;

      .inline-block;

      margin: auto 0;

      &.full-width {
        width: 23.5rem;
        width: @progress-section-full-width;
      }

      &.split-width {
        width: 10.5rem;
        width: @progress-section-split-width;
      }

      .progress-label {
        .text-ellipsis();
        text-align: center;
        margin: 0 0 @ax-spacing-xs;
        line-height: normal;
      }

      .progress-bar {
        .relative;
        .border-radius(0);
        border: none;
        z-index: @progress-bar-index;
        background: @white;
        height: @progress-bar-height;
        padding: @progress-bar-border-width 0;
        margin: 0;

        .progress-bar-inset {
          .relative;
          .border-radius(0 @progress-bar-radius @progress-bar-radius 0);
          background: @primary-blue;
          z-index: @midground-index;
        }
      }
    }
  }
}

.assessment-progress-scope();

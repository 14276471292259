
@axDatePicker-selectedDay-background-color: @ax-color-semantic-informational;
@axDatePicker-selectedDay-color: @ax-color-white;

@axDatePicker-today-background-color: 'transparent';
@axDatePicker-today-background-color-hover: rgba(@ax-color-grey-80, @ax-opacity-s);
@axDatePicker-today-border-color: @ax-color-grey-80;

:root {
  --axDatePicker-selectedDay-background-color: @axDatePicker-selectedDay-background-color;
  --axDatePicker-selectedDay-color: @axDatePicker-selectedDay-color;
  --axDatePicker-today-background-color: @axDatePicker-today-background-color;
  --axDatePicker-today-background-color-hover: @axDatePicker-today-background-color-hover;
  --axDatePicker-today-border-color: @axDatePicker-today-border-color;
}
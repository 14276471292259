@import (reference) "@common/prerequisites/prerequisites.less";

.multi-filter-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 1em;
  box-sizing: content-box;

  & * {
    box-sizing: inherit;
  }
}

.multi-filter-layout {
  width: 100%;

  .screen-md-portrait-min({
    max-width: 590px;
  });

  &.force-advanced .save-checkbox-wrapper {
    display: none;
  }

  & .icon-eye_open {
    padding: @ax-spacing-s 0 @ax-spacing-s @ax-spacing-m;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 999;
    height: 467px;
    display: none;
    .spinner {
      display: block;
    }
    .screen-sm-max({
      height: 100%;
      .spinner{
        top: 30%;
      }
    });
  }

  .quick-filter-hierarchy-selector, .multi-filter-edit-view{
    .spinner-wrapper {
      position: absolute;
      width: 100%;
      .spinner {
        left: 40%;
      }
    }
  }

  & a:hover, & a:focus {
    text-decoration: none;
  }

  & .grouped-filterable-selector {
    .border-radius(@ax-border-radius-m);
  }
}

.search-filter-wrapper {
  width: 100%;
}

.opened,
.filter-opened {
  .grouped-filterable-selector-empty-view {
    text-align: center;
    margin-top: @ax-spacing-xl;
    color: @ax-color-text-secondary;

    .icon-advanced-filter {
      font-size: 2.5em;
    }
  }
}

.search-filter-wrapper .input-container {
  .filter-opened & {
    max-width: 100%;
    .screen-sm-max({
      padding: 0.5em @ax-gutter-page;
    });
  }
}

.multi-filter-layout .filterable-selector-label {
  display: none;

  &.enabled {
    display: block;
    padding-bottom: 0.3em;
  }
}

.multi-filter-layout.filter-opened .filterable-selector {
  .input-wrapper input {
    .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  }

  .dropdown-container {
    border-top: 1px solid @secondary-grey;

    .screen-lg-min({
      height: 467px;
    });

    .highlight {
      background: rgba(75, 231, 12, 0.4);
      color: @black;
    }

    .filterable-selector-item .row-value {
      .text-ellipsis;
      .flex(1 1 auto);
    }

    .quick-filtering-item-row {
      border-bottom: .1rem solid @accent-grey;

      &.selected, &:hover, &:focus {
        background-color:@element-hover-bright;
      }
      &:hover, &:focus {
        cursor: pointer;
      }

      .quick-filtering-button {
        padding: @ax-spacing-m;
        width: 100%;
        text-align: left;
      }

      span {
        vertical-align: middle;
      }
      .icon-advanced-filter {
        font-size: @icon-nav-size;
      }
      .quick-filter-row-text {
        padding-left: 0.5em;
      }
      .quick-filter-dynamic-icon {
        padding-top: 5px;
        color: @primary-grey;
        font-size: inherit;
      }
    }

    .filterable-selector-more-item a {
      color: @primary-blue;
    }
  }
}

.advanced-filter-wrapper {
  position: relative;
  width: 100%;

  .advanced-dropdown-container {
    .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
    display: none;
    background-color: @white;
    width: 100%;
    z-index: 32;
    border: 0px none;
    .screen-md-portrait-min({
      border: 1px solid @secondary-grey;
    });
  }

  .advanced-dropdown-container .filterable-selector .dropdown-container {
    border: 0px none;
    .screen-md-portrait-min({
      width: ~"calc(100% + 2px)";
      left: -1px;
      border: 1px solid @secondary-grey;
    });
  }

  .btn {
    &.btn-default {
      .border-radius(@ax-border-radius-m);
      position: absolute;
      width: 50px;
      right: 0px;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      text-align: center;
      padding: 0.5em 0.25em;
      z-index: 32;
      .screen-md-portrait-min({
        width: 115px;
      });
    }

    .icon {
      position: relative;
    }
    .icon-th_list {
      top: 2px;
    }
    .icon-th_list:before {
      content: '\e954';
    }
    .btn-label {
      display: none;
      .screen-md-portrait-min({
        display: inline;
        padding: 0 0.5em;
      })
    }
    .icon-chevron_down, .icon-remove, .icon-advanced-filter {
      vertical-align: middle;
    }
    .icon-chevron_down, .icon-remove {
      bottom: 1px;
    }
  }

  .quick-filter-view {
    height: 100%;
    .tree-selector-container {
      background-color: @primary-bg;
      height: 100%;
    }
    .quick-filter-tree-selector {
      height: 100%;
    }
    .quick-filter-hierarchy-selector {
      height: 100%;
      .css-clear-input {
        top: 8px;
        right: 45px;
        height: 100%;
      }
      .clear-quick-search {
        cursor: pointer;
        width: 35px;
        line-height: 3.5rem;
        z-index: @page-index + 2;
        position: absolute;
        top: 40px;
        right: 10px;
        opacity: 0.5;
        display: none;
        text-align: center;

        &.top-offset {
          top: 82px;
        }
        &:hover, &:focus {
          opacity: 1.0;
        }
      }
      .quick-filter-input-container {
        padding: 0.5em 0 0.5em 0.5em;
        width: 97%;

        .input-wrapper {
          min-width: 100px;
          width: 100%;
          height: 36px;
          margin-right: 0.5em;

          input {
            background: @white;
          }
        }
      }

      .quick-filter-dropdown-container {
        tr.root span.fancytree-node,
        span.fancytree-node.root {
          margin-left: 0;
        }

        tr.root {
          .fancytree-node {
            padding: 0.5rem 0;
          }

          .fancytree-node--no-space-bottom {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }

          td {
            padding: 0; // allows for white background to left of inclusion-button-group
          }

          &.root__show-buttons .fancytree-title  {
            margin-bottom: 0.7rem; // increase margin since buttons take up header space
          }
        }

        // align checkboxes as far left as we can with the inclusion buttons without losing indentation on children nodes
        span.fancytree-node {
          margin-left: -1.5rem;
        }

        .fancytree-title {
          margin: 0 @ax-spacing-xxs @ax-spacing-xxs;
        }

        .inclusion-button-group {
          .inclusion-button--include, .inclusion-button--exclude {
            .flex(0.25);
          }
        }
      }

      .close-dropdown {
        font-size: @icon-nav-size;
        line-height: 2rem;
        opacity:0.8;
        &:hover {
          cursor: pointer;
          opacity:1.0;
        }
        .screen-sm-max({
          margin: 0 3px;
        })
      }
      .save-checkbox-wrapper {
        clear: both;
        padding: 0 0 @ax-spacing-s;

        .make-public-checkbox-wrapper {
          display: none;
          margin-right: 1em;
        }

        label {
          margin: 0;
        }
      }

      .filter-name-input {
        display: none;
        width: 100%;
        background-color: @white;
        margin-bottom: @ax-spacing-m;
        box-sizing:border-box;
      }

      .rederror {
        border: 1px solid @red;
      }

      .selection-details-container {
        border-top: .1rem solid @secondary-grey;
        background-color: @white;
        padding: @ax-spacing-s;

        .show-selected-container {
          display: none;
        }

        input {
          margin: 15px 0;
        }
      }

      .dropdown-container {
        .border-radius(0);
        display: block;
        position: relative;
        border-bottom: none;
        height: ~'calc(100% - 14.6rem)';
        .screen-md-portrait-min({
          max-height: 45rem;
        });
      }

      .quick-filter-collection-view {
        position: relative;

        .icon-not-started {
          color: @ax-color-grey-60;
        }

        .icon-asterisk {
          color: @ax-color-red-60;
        }

        .icon-medium {
          color: @ax-color-bronze;
        }

        .help-wrapper {
          display: inline-block;
          float: right;
          margin-right: 5px;
          margin-top: 2px;
          position: absolute;
          right: 5px;

          .hoverover {
            display: none;
            max-width: 30rem;
            width: 80vw;
            white-space: normal;
            margin-top: 0;
            margin-right: 6px;
            background: @white;
            border: 1px solid @primary-blue;
            z-index: 999;
            text-align: left;
            line-height: auto;
            padding: 1em;
            .ax-breakpoint-m({
              width: 30rem;
            });

            // show tooltip over all other elements since scroll container not available on hover
            .screen-md-portrait-min({
              position: fixed;
            });

            // keep tooltip positioned inside of component for mobile so you can scroll through its contents
            .screen-md-portrait-max({
              position: absolute;
              top: -6rem;
              left: auto;
              right: 1rem;
            });
          }

          .screen-sm({
            margin-top: 8px;
          });
          .screen-md-landscape({
            margin-top: 8px;
          });
        }

        .fancytree-node .must-include {
          display: none;
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 2rem;
          line-height: 2.2rem;
          padding-right: 1px;
          cursor: pointer;
        }

        .fancytree-node .must-include:focus,
        .fancytree-title[data-focus-visible-added],
        .help-wrapper.focus-visible {
          .ax-focus-keyboard();
        }

        .scrollable-content-wrapper {
          height: 100%;
        }

        .tree-wrapper {
          height: 100%;
        }
      }

      .button-container {
        .border-radius(0 0 @ax-border-radius-m @ax-border-radius-m);
        bottom: 0;
        position: absolute;
        z-index: @max-index;
        width: ~'calc(100% - 2px)';
        .screen-md-portrait-min({
          position: relative;
          width: 100%;
          left: -1px;
          z-index: 0;
        });
        border: .1rem solid @secondary-grey;
        background-color: @primary-bg;
        text-align: center;

        .apply-button {
          min-width: 12rem;
          margin: @ax-spacing-s;
        }
      }
    }
  }

  .multi-filter-edit-view {
    height: 100%;

    .filter-title {
      background-color: @primary-bg;
      padding: 1em;
      border-bottom: 1px solid @secondary-grey;
      .display-flex();

      .title-text {
        overflow-wrap: break-word;
        min-width: 0;
      }

      .icon-remove {
        font-size: @icon-nav-size;
        margin-left: 0.5em;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .criteria-details-wrapper {
      height: ~"calc(100% - 132px)";

      .criteria-title {
        margin: 1em;
        padding-left: 1em;
        background-color: @primary-bg;
        line-height: 3rem;
        font-weight: @ax-font-weight-medium;
      }
      .criteria-selection {
        margin-left: 30px;
      }
    }

    .edit-view-button-bar {
      width: 100%;
      bottom: 0;
      position: absolute;
      padding: 1em 0;
      background-color: @primary-bg;
      text-align: center;
      .delete {
        min-width: 70px;
        vertical-align: top;
      }
    }
  }
}

.advanced-opened .advanced-filter-wrapper .advanced-dropdown-container {
  display: block;
  position: fixed;
  top: 36px;
  left: 0px;
  bottom: 0px;

  .screen-md-portrait-min({
    height: 500px;
    top: -36px;
    position: absolute;
  });
  &:after {
    content: '';
    clear: both;
  }

  & .title-wrapper {
    display: block;
    position: fixed;
    top: 36px;
    right: 0px;
    margin:  0 0 0.75em 0;
    font-weight: @ax-font-weight-bold;
    min-height: 18px;

    .css-close-filter {
      background-image: url('@common/assets/images/clear-search.png');
      background-position: center center;
      background-repeat: no-repeat;
      right: 0.5em;
      left: auto;
      top: 8px;
      width: 36px;
      height: 36px;
      position: absolute;
      .opacity(1.0);
    }

    .screen-md-portrait-min({
      display: none;
    });

    & div {
      display: none;
    }
  }

  .quick-filter-hierarchy-selector .dropdown-container {
    .screen-md-landscape-max({
      height: ~"calc(100% - 180px)";
    });
    top: 0px;
  }
}

.margin-top-xs {
  margin-top: 0.25em;
}
.margin-bottom-sm {
  margin-bottom: 0.5em;
}

.bulk-edit-attribute-modal {
  .error {
    color: @red;
    margin-bottom: @ax-spacing-s;
  }
  .bulk-edit-textarea {
    width: 100%;
    resize: none;
    height: 30rem;
    overflow-y: auto;
    margin-top: @ax-spacing-m;
    white-space: pre-wrap;
  }
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset.bulk-edit-modal-footer {
  float: right;
}

.inclusion-button-group {
  .display-flex();
  .flex-direction(row);
  background: white;
  padding: 1rem;
  border-top: 1px solid @secondary-bg;
  margin-bottom: -1rem;

  .inclusion-button--include, .inclusion-button--exclude {
    padding: 0;
    .flex(1);
    font-size: @ax-font-size-xs;
  }

  .inclusion-button--include {
    .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
  }

  .inclusion-button--exclude {
    .border-radius(0 @ax-border-radius-m @ax-border-radius-m 0);
  }

  .selected {
    background-color: @ax-color-blue-60;
    color: @ax-color-white;
  }
}

@bail-button-border: rgba(0,0,0,.16);

.page-title-bar-layout {
  background-color: @primary-bg;

  .page-title-region-wrapper {
    width: 100%;

    .page-title-bail-region .assessment-bail-button {
      text-align: end;

      button {
        .border-radius(@ax-border-radius-m);
        font-size: 2rem;
        height: 4rem;
        padding: 0 1rem;
        border: solid .1rem @bail-button-border;
        background-color: @white;

        &:hover,
        &.focus-visible:focus {
          background-color: @ax-color-grey-20;
        }

        &.focus-visible:focus {
          .ax-focus-keyboard();
        }
      }
    }

    .page-title-left-region {
      // Don't allow column width to go below 0
      min-width: auto;
    }

    .page-title-right-region {
      z-index: 0;

      // This type of CSS is not something that should replicated except in the unique case like here where both width and heihght
      //    breakpoints need to be enforced for the style to be applied.
      @media (max-height: (@ax-breakpoint-height-max-xxs - 1px)) and (max-width: @ax-breakpoint-mobile-max) {
        display: none;
      }
    }

    .ax-breakpoint-m({
      .page-title-right-region {
        margin-right: @ax-spacing-l;
      }
    });

    .ax-breakpoint-l({
      .page-title-bail-region .assessment-bail-button {
        button {
          font-size: 3rem;
        }
      }
    });
  }
}

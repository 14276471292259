/*==QUESTION AREA
===============================*/
.imgwrap{
  position:relative;
  &.imgwrap:hover {
    cursor: pointer
  }
  text-align: center;
}

/*==Question Ask ==*/
.questionarea {
  .display-flex();
  padding: @ax-gutter-page @ax-gutter-page 0;
  position:relative;
  .border-radius(@ax-border-radius-m @ax-border-radius-m 0 0);
  min-height: 10rem;
}

.questionarea .questionask {
  .flex(1 1 auto);
  position:relative;
  z-index: 999;
  padding-bottom: @ax-spacing-xxl;
  word-break: break-word;
}

.questionarea .questionimage {
  position:relative;
  .flex(2.5);
  text-align: left;
  margin: 0 @ax-spacing-m @ax-spacing-s 0;
  z-index: 600;
  img {
    max-height: 90px;
  }

  .ax-breakpoint-m({
    float: left;
  })
}

.lt-ie9 .questionarea .questionimage {
  display: inline;
  float: none;
}

.lt-ie8 .questionarea .questionimage {
  display: block;
  float: left;
}

.lt-ie10 .questionarea .questionimage {
  img {
    max-height: 120px;
  }
}

.questionarea .questionimage.center {
  float: none;
  margin: 0 auto 1em auto;
}

.questionarea .questionimage img.modal {
  padding: 0.4em;
  margin: 0 auto;
  vertical-align: middle;
  text-align: left;
  max-width: 250px;
  max-height: 290px;
  .border-radius(@ax-border-radius-m);
}

.hotspotpreview .questionarea .questionimage {text-align: center;}

.hotspotpreview .contentarea .questionimage img.modal.hotspot {
  max-width: 700px;
  max-height: 600px;
  margin: 0 auto;
  text-align: center;
}

.hotspotpreview p.question {padding:1em 0 1em 0; width: 100%;}

/*==Question Type ==*/

.survey .banner {
  .relative;
  font-weight: @ax-font-weight-medium;
  font-size: @topic-font-size;
  padding: 0.5em 1em;
  background: @red;
  color: @white;
}


/*==Question Answering ==*/

.hinttext {
  display: none;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 0;
  position: relative;
  min-width: 100%;
}

.hotspot .hinttext,
.matching .hinttext,
.matching-question .hinttext {
  display: block;
  height: 20px;
  border-top: solid 2px @primary-grey;
}

.fitb {
  .no-margin-collapse();

  .hinttext {
    &.correct,
    &.incorrect {
      margin-bottom: @ax-line-height-l;
    }
  }
}

.matching-question .layer-overlay-container {
  display: inline-block;
}

.hinttext.correct,
.hotspot .hinttext.correct,
.matching .hinttext.correct,
.matching-question .hinttext.correct {
  height: auto;
  border-top: solid 2px @correct-green;
  border-bottom: solid 2px @correct-green;
}

.hinttext.incorrect,
.hotspot .hinttext.incorrect,
.matching .hinttext.incorrect,
.matching-question .hinttext.incorrect {
  height: auto;
  border-top: solid 2px @red;
  border-bottom: solid 2px @red;
}

.hinttext.empty,
.hotspot .hinttext.empty,
.matching .hinttext.empty,
.matching-question .hinttext.empty {
  border-bottom: 0;
  height: auto;
  #reason {
    background: none;
  }
}

.hinttext .question-instruction {
  min-height: 3rem;
  width: 300px;
  border: 2px solid @primary-grey;
  .border-radius(@ax-border-radius-m);
  padding: 0;
  position: relative;
  top: 1.2rem;
  margin: -3rem auto 0 auto;
  line-height: 3rem;
  color: @ax-color-text;
  z-index: 999;
  font-weight: @ax-font-weight-bold;
}

.hinttext.correct .question-instruction,
.hinttext.incorrect .question-instruction {
  border: none;
}

.hinttext.correct + .answerwrappersize .answerwrapper,
.hinttext.incorrect + .answerwrappersize .answerwrapper {
  border-top: none;
}

#accessibility-note {
  top: 0;
  left: 0;
}

.hotspot .hinttext .question-instruction,
.multianswer .hinttext .question-instruction,
.matching .hinttext .question-instruction,
.matching-question .hinttext .question-instruction {
  background: @white;
}

.hinttext .explanation .correct-incorrect {
  background: #595959;
  float:left;
  width: 50%;
  .border-radius(@ax-border-radius-m 0 0 @ax-border-radius-m);
  padding: 0;
}

.hinttext .correct .correct-incorrect,
.hinttext .incorrect .correct-incorrect {
  float:left;
  width: 50%;
  padding: 0;
  color: @ax-color-text;
  font-weight: @ax-font-weight-bold;
}

.hinttext .correct .balance-points {
  float: right;
  width: 49%;
  padding: 0;
  border-left: 2px solid @correct-green;
  color: @ax-color-text;
  cursor: default;
}

.hinttext .incorrect .balance-points {
  float: right;
  width: 49%;
  padding: 0;
  border-left: 2px solid @red;
  color: @ax-color-text;
  cursor: default;
}

.hinttext .balance{
  padding:0 0 0 5px;
}
.hinttext .correct-incorrect,
.hinttext .balance-points{
  font-size: @ax-font-size-xs;
  display: block;
  height: 3rem;
  line-height: 3rem;
  overflow: hidden;
  margin: 0;
}

.hinttext .correct-incorrect.full-size{
  width:100%;
  .border-radius(@ax-border-radius-m);
}

.hinttext #balance-points-wrapper {
  .border-radius(@ax-border-radius-m);
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  background-color: @white;
  .box-shadow-black(0px, 1px, 2px, 0.1);

  &.correct {
    border: 2px solid @correct-green;
  }
  &.incorrect {
    border: 2px solid @red;
  }

  .correct-incorrect {
    margin: 0;
  }
}

.answerwrapper {
  border-top: 2px solid @primary-grey;
}

.fitb .answerwrapper {
  border: 0;
  display: block;
  width: 100%;
  min-height: 25rem;
}

.answerwrapper,
.optionsarea {
  position:relative;
  background:@white;
}

.answerwrapper {
  .icon-ok_sign {
    display: none;
  }

  .icon-ban_circle {
    display: none;
  }
}

.answerwrapper .answer .radiobutton {
  width: 3rem;
  height: 3rem;
  .box-sizing(content-box);
  background-image: url('@/assets/questions/radio_select.png');
  background-repeat: no-repeat;
  background-position: 0 0px;
  background-size: 3.2rem;
}

.answerwrapper .answer .answerradio {
  width: 3.1rem;
  border: 1px solid transparent;
  padding: 0.5em;
  margin: 0.5em;

  &.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }
}

.question-preview-modal {
  #answer-body {
    .answerradio {
      outline: 0;
      border: 0;
    }
  }
}

.cols-4 .answerwrapper .answer .answerradio,
.cols-5 .answerwrapper .answer .answerradio {
  display: block;
  height: 32px;
  margin: 0 auto;
}

.cols-4 .answerwrapper .answer .radiobutton,
.cols-5 .answerwrapper .answer .radiobutton  {
  display: block;
  float: none;
  margin: 0 auto;
}

.answerwrapper .answer .answerradio:focus {
  border: 1px solid @primary-blue;
}
.answerwrapper .answer .answerradio:active {
  border: 1px solid transparent;
}

.answerwrapper .selected .radiobutton {
  background-position: 0 -3.2rem;
}

.answerwrapper .answer {
  position:relative;
  background:@white;
  border-bottom:1px solid @accent-grey;
  margin:0 auto;
  z-index: 5;
  display: block;
  padding: @ax-spacing-s @ax-spacing-m;
  cursor: default;
  outline: 0;
}

.answerwrapper {
  .answer.wrong {
    background:url('@/assets/questions/wronganswerbg.gif') top left repeat;
    color: @ax-color-grey-50;

    .radiobutton,
    .multicheck,
    .table-cell-helper.answerimage,
    .table-cell-helper.reason-image
    td.answerimage,
    td.reason-image {
      opacity: 0.7;
    }
  }
}

.matched .incorrect td.option-image {
  opacity: 0.3;
}
.survey .answerwrapper .answer {
  border-bottom: none;
}

.cols-4 .answerwrapper .answer,
.cols-5 .answerwrapper .answer {
  float: left;
  padding-left: 0;
  padding-right: 0;
}

.cols-4 .answerwrapper .answer {
  width: 25%;
}

.cols-5 .answerwrapper .answer {
  width: 20%;
}

.answerwrapper .answer.wrong {
  background:url('@/assets/questions/wronganswerbg.gif') top left repeat;
  color: @ax-color-grey-70;
}

.answerwrapper .answer.wrong .radiobutton,
.answerwrapper .answer.wrong .multicheck {
  opacity: 0.7;
}

.answerwrapper .notselected .table-cell-helper.answertext,
.answerwrapper .notselected td.answertext {
  background-image: none;
}

.answerwrapper .selected.wrong .answertext {
  background-image: url('@/assets/questions/incorrectcheck.png');
}

.answerwrapper .correct .answertext {
  background-image: url('@/assets/questions/correctcheck.png');
}

.answerwrapper .incorrect .answertext,
.answerwrapper .wrong .answertext {
  background-image: url('@/assets/questions/incorrectcheck.png');
}

.answerwrapper .incorrect .answertext,
.answerwrapper .wrong .answertext,
.answerwrapper .correct .answertext,
.answerwrapper .selected.wrong .answertext {
  background-position: 98% center;
  background-repeat: no-repeat;
}

.answerwrapper .answer .answerspacer {
  position: relative;
}

.answerwrapper .answer .table-helper,
.answerwrapper .answer table {
    position:relative;
    width: 100%;
}

.answerwrapper .answer .table-helper .table-cell-helper,
.answerwrapper .answer table td {
  vertical-align:middle;
  border:0;
}

.answerwrapper .answer .table-helper .table-cell-helper.answerletter,
.answerwrapper .answer table td.answerletter {
  width:5rem;
}

.answerwrapper .answer .letterlabel {
  background:@white;
  float:left;
  padding:0;
  display:none;
  .border-radius(@ax-border-radius-m);
  font-size: @ax-font-size-body;
  text-align:center;
  margin:0 auto;
  border:1px solid @accent-grey;
  text-transform: uppercase;
  padding:0.5em 0.7em 0.5em 0.7em;
}

.answerwrapper .answer .table-cell-helper.answertext,
.answerwrapper .answer .table-cell-helper.explanationtext,
.answerwrapper .answer td.answertext,
.answerwrapper .answer td.explanationtext {
  word-wrap: break-word;
  margin: 0;
  vertical-align: middle;
  text-align: left;
  padding-right: 6rem;

  label {
    margin: 0;
    font-weight: @ax-font-weight-regular;
    font-size: inherit;
    line-height: inherit;
  }
}

.answer-text-limit-max-width {
  .table-cell-helper {
    &.answertext,
    &.explanationtext {
      max-width: 1px; //SE-14122 This hack will let IE use word-wrap correctly, because of table cell this max-width is not applied

    }
  }
}

.answerwrapper .answer .table-cell-helper.answerimage,
.answerwrapper .answer .table-cell-helper .reason-image,
.answerwrapper .answer td.answerimage,
.answerwrapper .answer td .reason-image {
  display:table-cell;
  position:relative;
  width: 80px;
}

.answerwrapper .answer .table-cell-helper.answerimage.empty,
.answerwrapper .answer td.answerimage.empty {
  width: 1px; /* This is to force the layout engine to use the widths described (and paddings) and not auto-size */
  padding: 0;
}

.answerwrapper .answer .table-cell-helper.answerimage .image-wrapper,
.answerwrapper .answer td.answerimage .image-wrapper {
  position: relative;
  top: 0px;
  width: 80px;
}

.answerwrapper .answer .table-cell-helper.answerimage img,
.answerwrapper .answer td.answerimage img {
  margin:0;
  padding:0;
  vertical-align: middle;
  max-height: 90px;
}

.lt-ie10 .answerwrapper .answer .table-cell-helper.answerimage img,
.lt-ie10 .answerwrapper .answer td.answerimage img {
  max-height: 80px;
}

.cols-4 .answerwrapper .answer .table-cell-helper.answertext,
.cols-5 .answerwrapper .answer .table-cell-helper.answertext,
.cols-4 .answerwrapper .answer td.answertext,
.cols-5 .answerwrapper .answer td.answertext {
  display: block;
  text-align: center;
  padding: 1.5em 0.5em;
}

.answerletter .multicheck {
  background:url('@/assets/questions/multicheck.gif') 0px 0px no-repeat;
  background-size: 3.2rem;
  width: 3.3rem;
  height: 3.2rem;
  display: block;
  border: .2rem solid @ax-color-grey-50;
}

.answer.focus-visible:focus .answerletter .multicheck {
  border-color: @primary-blue;
}

.answerletter .checked {
  background-position: 0px -3.2rem;
}

#reason {
  display: none;
  text-align: left;
  padding: 2em 1em;
  .gradient(@startColor: @primary-bg, @endColor: @accent-grey);
  .box-shadow-black(inset 0px, -1px, 2px, 0.1);
  .reason-image {
    float: left;
    padding-right: 1rem;
    img {
      max-height: 90px;
    }
  }

  #reasonContent a.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }
}

.lt-ie10 #reason .reason-image {
  img {
    max-height: 120px;
  }
}

.answerwrapper .answer .answertext .optionReasonText {
  margin:0.5em 0 0 0;
}

.survey .comment {
  margin: 1.5em;
}

.survey .comment textarea {
  outline: none;
  resize: none;
  width: 100%;
  height: 6em;
  .box-sizing(border-box);
}

.select-all-note {
  .display-flex();
  .align-items(center);
  margin: 0 0 @ax-spacing-xs @ax-spacing-s;
  position: absolute;
  bottom: 0;

  span, .icon-CorrectAnswerStreak1 {
    color: @ax-color-blue-80;
  }

  span {
    margin-left: @ax-spacing-s;
    font-weight: @ax-font-weight-medium;
  }

  .icon-CorrectAnswerStreak1 {
    border: none;
    font-size: 3.4rem;
    font-weight: @ax-font-weight-bold;
  }
}

/*=============== Matching Question Variant =============*/

.optionsarea.mq-preview {
  padding: 2em 1em 0em 1em;

  .mq-preview-wrapper {
    .display-flex();
    .justify-content(space-between);
    border: 1px solid @primary-grey;
    .border-radius(@ax-border-radius-m);
    margin-bottom: 2.4rem;
    position: relative;

    &.incorrect {
      background:url('@/assets/questions/wronganswerbg.gif') top left repeat;
    }
  }

  p {
    text-align: center;
    margin-bottom: 0;
  }

  .mq-preview-option {
    .display-flex();
    .align-items(center);
    .justify-content(center);
    width: calc(50% - 32px);
    padding: 1rem;
    min-height: 60px;
  }

  .mq-preview-result-indicator {
    position: absolute;
    display: block;
    height: 32px;
    z-index:10;
    left: calc(50% - 16px);
    border: 0;
    top: 35%;
    width: 32px;


    &.middle-correct {
      background: url('@/assets/questions/correctcheck.png') 50% 50% no-repeat;
    }

    &.middle-incorrect {
      background:url('@/assets/questions/incorrectcheck.png') 50% 50% no-repeat;
    }
  }
}

/*=============== FILL IN THE BLANKS =============*/

.fitbleftside {display:inline; z-index: 999; position:relative;}
.fitbrightside{display:inline;}

.fitbimage {float:left; margin: 0 1em 0 0; position:relative;}

.correctanswertitle {margin-left: 1em;}

.fillintheblanks {
  display: block;
  margin: 0;
  text-align: left;
  z-index:100;
}

.fillintheblanks .questionask {
  line-height: @ax-spacing-xxl;
  z-index: 580;
  padding-right: 1.5em;

  span {
    display: inline-block;
    vertical-align: middle;
  }
  a.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }
}

.fillintheblanks .questionask span.plaintext{margin-bottom:1em;}

.fillintheblankcustomdrop .largedropdowncontainer {
  background-image: url('@/assets/questions/down-arrow.gif');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-color: @ax-color-white;
  border: 1px solid @ax-color-grey-40;
  .border-radius(@ax-border-radius-m);
}

.fillintheblankcustomdrop .largedropdowncontainer.incorrect,
.fillintheblankcustomdrop .largedropdowncontainer.correct {
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.fillintheblankcustomdrop .largedropdowncontainer.incorrect {
  margin-right: @ax-spacing-xs;
}

.fillintheblankcustomdrop .largedropdowncontainer span.selection {
  display: block;
  min-width: 200px;
  padding: 0 @ax-spacing-m 0 @ax-spacing-s;
  margin-bottom: 0;
  line-height: @ax-line-height-m;
  background-color: @ax-color-grey-20;
  border-top-left-radius: @ax-border-radius-m;
  border-top-right-radius: @ax-border-radius-m;
  &:before {
    content: '';
    font-family: @icomoon-font-family;
    font-size: @ax-font-size-l;
    margin-right: @ax-spacing-xs
  }
}

.blankanswer button.selection-box {
  min-width: 200px;
  padding: 0 @ax-spacing-m 0 @ax-spacing-s;
  margin-bottom: 0;
  line-height: @ax-line-height-m;
  text-align: left;
  background-image: url('@/assets/questions/down-arrow.gif');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-color: @ax-color-white;
  color: @black;
  border: 1px solid @ax-color-grey-40;
  .border-radius(@ax-border-radius-m);
  -webkit-appearance: none;

  &.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }
}

.fillintheblankcustomdrop .largedropdowncontainer.correct span.selection,
.fillintheblankcustomdrop .largedropdowncontainer.incorrect span.selection,
.blankanswer button.selection-box.answered {
  min-width: auto;
}

.blankanswer.active button.selection-box,
.blankanswer button.selection-box.answered {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.fillintheblankcustomdrop .largedropdowncontainer.correct span {
  border: none;
  border-bottom: 2px solid @ax-color-green-60;
  &:before {
    content: @icon-ok_circle;
    color: @ax-color-green-60;
  }
}

.fillintheblankcustomdrop .largedropdowncontainer.incorrect span {
  border: none;
  border-bottom: 2px solid @red;
  text-decoration: line-through;
  display: block;
  padding-left: .5em;
  &:before {
    content: @icon-remove_circle;
    color: @ax-color-red-60;
    text-decoration: none;
    display: inline-block;
  }
}

.fillintheblanks .correctforincorrect {
  border-top-left-radius: @ax-border-radius-m;
  border-top-right-radius: @ax-border-radius-m;
}

.fillintheblanks .correctforincorrect,
.blankanswer .selection-box.answered {
  border: none;
  background: @ax-color-grey-20;
  color: @ax-color-black;
  padding-right: @ax-spacing-s;
  &:before {
    content: '';
    font-family: @icomoon-font-family;
    font-size: @ax-font-size-l;
    margin-right: @ax-spacing-xs
  }
}

.fillintheblanks .correctforincorrect,
.blankanswer .selection-box.correct {
  border-bottom: 2px solid @green;
  &:before {
    content: @icon-ok_circle;
    color: @ax-color-green-60;
  }
}

.blankanswer .selection-box.incorrect {
  border-bottom: 2px solid @red;
  background: @ax-color-grey-20;
  text-decoration: line-through;
  display: block;
  margin-right: @ax-spacing-xs;
  &:before {
    content: @icon-remove_circle;
    color: @ax-color-red-60;
    text-decoration: none;
    display: inline-block;
  }
}

.fillintheblanks .correctforincorrect {
  padding: 0 @ax-spacing-s;
  line-height: @ax-line-height-m;
  vertical-align: middle;
}

.fillintheblankcustomdrop {
  display: inline-block;
  position: relative;
  margin: 0 0 0 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;

  .question-preview-content & {
    cursor: default;
  }
}

.blankanswer .selectorlist {
  position: absolute;
  // NOTE: (line-height: .blankanswer button.selection-box) + ((line-height: .fillintheblanks .questionask) - (line-height: .blankanswer button.selection-box)/2)
  top: calc(@ax-line-height-m + (@ax-spacing-xxl - @ax-line-height-m)/2);
  left: 0;
  right: 0;
  border: 1px solid @ax-color-grey-40;
  box-sizing: border-box;
  list-style: none;
  display: none;
  z-index: 998;
  text-align: left;
  overflow: auto;
  background: @white;
  min-width: 20rem;
}

.fillintheblankcustomdrop .largedropdowncontainer .selectorlist ul {
  border:1px solid @primary-grey;
}

.fillintheblankcustomdrop .largedropdowncontainer .selectorlist ul li {
  padding: 0.5em;
  display: block;
  list-style-position: outside;
  text-decoration: none;
  border-top:1px solid @primary-grey;
  background-color: @white;
  line-height:normal;
  &:hover, &:focus {
    background:@element-hover-bright;
  }
  &.selected {
    background:@white;
  }
  &:first-child {
    border: 0px;
  }
}

.blankanswer .selectorlist li {
  padding: 0.5em;
  display: block;
  list-style-position: outside;
  text-decoration: none;
  border-top:1px solid @primary-grey;
  background-color: @white;
  line-height:normal;

  &.selected,
  &:hover {
    background:@element-hover-bright;
  }

  &.focus-visible:focus {
    box-shadow: inset 0px 0px 2px 2px @primary-blue;
  }

  &:first-child {
    border: 0px;
  }
}

/* Active state */
.blankanswer.active {
  z-index: 1000;
  .selectorlist {
    display: block;
  }
}

.fillintheblanks .blankanswer {
  position:relative;
  .largedropdowncontainer .selectorlist {
    display: none;
  }

  &.active{
    z-index:1000;
    .largedropdowncontainer .selectorlist {
      display: block;
    }
  }
}

/*=========== HOTSPOT QUESTION ===============*/

.hotspot .answerwrapper {
  text-align: center;
  padding: 1em;
  border-top: 0;
}

.hotspotimagewrapper {
  display: inline-block;
  margin: 15px auto auto;
  position: relative;
  min-height: 250px;
}

img.hotspotimage {
  display: block;
  margin: 0 auto;
}

.hotspotmarker {
  height: 20px;
  width: 20px;
  background: url('@/assets/questions//hotspot_marker.png') center no-repeat;
  display: block;
}

.marker-icon {
  .align-items(center);
  border: @ax-spacing-xxs solid @ax-color-white;
  .border-radius(50%);
  color: @ax-color-white;
  .display-flex();
  font-size: @ax-font-size-s;
  .justify-content(center);
}

.correct-marker {
  background: @ax-color-semantic-positive;
}

.incorrect-marker {
  background: @ax-color-semantic-negative;
}

.hotspotmarkerwrapper {
  position: absolute;
  z-index: 10;
  line-height: 0;
}

.hotspotmarkerwrapper:hover {
  opacity: 0.7;
}

.contentarea .questionimage .hotspotmarkerwrapper {
  opacity: 1;
}

.hotspotimagewrapper .hotspotanswer {
  position: absolute;
}

.hotspotimagewrapper .correct,
.contentarea .questionimage .correct {
  background: rgba(@ax-color-white, 0.75);
  .backdrop-filter(blur(@ax-spacing-xxs));
  border: .3rem solid @ax-color-semantic-positive;
  .border-radius(@ax-border-radius-m);
}

.hotspotimagewrapper .correct .check {
  background: url('@/assets/questions/correctcheck.png') center no-repeat;
  width: 100%;
  height: 100%;
  display: block;
}

.hotspotimagewrapper .incorrect,
.contentarea .questionimage .incorrect {
  border: .4rem solid @ax-color-red-60;
  background: rgba(@ax-color-white, 0.8);
  .border-radius(@ax-border-radius-m);
}

.hotspotimagewrapper .incorrect .check {
  background: url('@/assets/questions/incorrectcheck.png') center center no-repeat;
  width: 100%;
  height: 100%;
  display: block;
}

.radioinputcontainer {
  display: flex;
}
.radiobutton {
  height: 3.2rem;
  width: 3.2rem;
  padding: .1rem;
}
.radiobutton .status {
  .border-radius(50%);
  border: .3rem solid @primary-grey;
  width: 3.2rem;
  height: 3.2rem;
  color: #ececec;
  overflow: hidden;
}
.selected .radiobutton .status {
  background: @primary-grey;
}
.selected .checked {
  display: inline-block;
}
.radioinputcontainer .label {
  padding-left: .5em;
}

.question-preview-container .answerwrapper {
  .no-margin-collapse();
  border: none;
}

/* SMALL MOBILE SCREENS ==============================*/

.screen-xs({
  .threecol {
    .leftcol,
    .rightcol {
      width: 49%;
    }
  }
});
.screen-sm-max({
  .matched .rightcol.correct td:first-child,
  .matched .rightcol.incorrect td:first-child {
    padding-left: 1.6em;
  }
  .matched .leftcol.incorrect td:last-child,
  .matched .leftcol.correct td:last-child {
    padding-right: 1.6em;
  }
  .middlecol div{
    width: 2em;
    margin-left: -1em;
  }

});

.screen-md-portrait-max({
  .multichoice .questionarea {
    margin-top: 1em;
  }
});

/* LARGE DESKTOP SCREENS ==============================*/

.screen-lg-max({
  .hinttext {
    display:none;
    margin: 0 auto;
    text-align: center;
    position: relative;
  }

  .hotspot .hinttext,
  .matching .hinttext,
  .matching-question .hinttext {
    display: block;
    height:30px;
    border-top: solid 2px @primary-grey;
  }

  .hinttext.correct,
  .hotspot .hinttext.correct,
  .matching .hinttext.correct,
  .matching-question .hinttext.correct {
    height: auto;
    border-top: solid 2px @correct-green;
    border-bottom: solid 2px @correct-green;
  }

  .hinttext.incorrect,
  .hotspot .hinttext.incorrect,
  .matching .hinttext.incorrect,
  .matching-question .hinttext.incorrect {
    height: auto;
    border-top: solid 2px @red;
    border-bottom: solid 2px @red;
  }

  .hinttext.empty,
  .hotspot .hinttext.empty,
  .matching .hinttext.empty,
  .matching-question .hinttext.empty {
    border-bottom: 0;
  }

  .hinttext .question-instruction {
    min-height: 3rem;
    padding: 0;
    position: relative;
    line-height: 3rem;
    color: @ax-color-text;
  }

  .hotspot .hinttext .question-instruction,
  .matching .hinttext .question-instruction,
  .matching-question .hinttext .question-instruction {
    background: @white;
  }

  .hinttext .correct .correct-incorrect,
  .hinttext .incorrect .correct-incorrect {
    float:left;
    width: 50%;
  }

  .hinttext .correct .balance-points,
  .hinttext .incorrect .balance-points {
    float:right;
    width: 49%;
  }

  .hinttext .balance{
    padding:0 5px 0 0;
  }

  .hinttext .correct-incorrect, .hinttext .balance-points{
    display: block;
    height: 3rem;
    overflow: hidden;
  }

  .hinttext .correct-incorrect.full-size{
    width:100%;
  }

  .hinttext #balance-points-wrapper{
    .border-radius(@ax-border-radius-m);
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    background-color: @white;
  }

  .answerwrapper {
    border-top: 2px solid @primary-grey;
    .table-cell-helper,
    td {
      padding-right:0.5em;
    }
  }

  .fitb .answerwrapper {
    border:0;
  }

  .answerwrapper, .optionsarea {
    position:relative;
    background:@white;
  }

  .answerwrapper .answer .radiobutton {
    width: 3rem;
    height: 3rem;
    background-image: url('@/assets/questions/radio_select.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 3.2rem;
  }

  .answerwrapper .answer .answerradio {width: 30px;}

  .answerwrapper .selected .radiobutton {
    background-position: bottom left;
  }

  .answerwrapper .answer.wrong {
    background:url('@/assets/questions/wronganswerbg.gif') top left repeat;
    color: @ax-color-grey-70;
  }

  .answerwrapper .answer.wrong .radiobutton,
  .answerwrapper .answer.wrong .multicheck,
  .answerwrapper .answer.wrong .table-cell-helper.answerimage,
  .answerwrapper .answer.wrong td.answerimage {
    opacity: 0.7;
  }

  .answerwrapper .notselected.wrong .answertext {background-image: none;}

  .answerwrapper .selected.wrong .answertext {
    background-image: url('@/assets/questions/incorrectcheckmobile.png');
  }

  .answerwrapper .correct .answertext {
    background-image: url('@/assets/questions/correctcheckmobile.png');
  }

  .answerwrapper .incorrect .answertext,
  .answerwrapper .wrong .answertext {
    background-image: url('@/assets/questions/incorrectcheckmobile.png');
  }

  .answerwrapper .selected.wrong .answertext,
  .answerwrapper .correct .answertext,
  .answerwrapper .incorrect .answertext,
  .answerwrapper .wrong .answertext {
    z-index: 999;
  }

  .answerwrapper .answer .table-helper .table-cell-helper,
  .answerwrapper .answer table td {
    vertical-align:middle;
    text-align:left;
    border:0;
  }

  .answerwrapper .answer .table-cell-helper.answerimage ,
  .answerwrapper .answer td.answerimage {
    display:table-cell;
    position:relative;
    width: 80px;
    padding: 0 0.7em 0 0;
    margin: 0;
  }

  .multichoice .answerwrapper .answer .table-cell-helper.answerimage,
  .multichoice .answerwrapper .answer td.answerimage {
    padding: 0 0.7em .5em 0;
  }

  .multichoice .question-text {
    .flex(1);
    margin-left: 0.5rem;
  }

  .answerwrapper .answer .table-cell-helper.answerimage .image-wrapper,
  .answerwrapper .answer td.answerimage .image-wrapper{
    position:relative;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .answerwrapper .answer .table-cell-helper.answerimage img,
  .answerwrapper .answer td.answerimage img {
    background-size: contain;
    max-width: 100%;
    max-height: 90px;

  }

  .answerwrapper .answer .table-cell-helper.answerletter,
  .answerwrapper .answer td.answerletter {
    width:3.2rem;
  }

  .answerletter .multicheck {
    background:url('@/assets/questions/multicheck.gif') top left no-repeat;
    background-size: 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
    display: block;
    border:.2rem solid @ax-color-grey-50;
  }

  .answerletter .checked {
    background-position: bottom left;
  }

  #reason {
    display: none;
    text-align: left;
    padding: 1.5em 1em 1em 1em;
    vertical-align: top;
    background-color: @primary-grey;
    word-break: break-word;
    .reason-image {
      padding-right: 0.5em;
      text-align: center;
      display: block;
      img {
        max-width: 100%;
        max-height: 90px;
      }
    }
  }

  .answerwrapper .answer .answertext .optionReasonText {
    margin:0.5em 0 0 0;
  }

  .survey fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: @ax-font-size-body;
    background: transparent;
    outline: 0;
  }

  .survey .comment {
    margin: 1.5em;
  }

  .survey .comment textarea {
    resize: none;
    width: 100%;
    height: 6em;
    .box-sizing(border-box);
  }

  .fitbimage {
    display: block;
    height: 120px;
    margin-bottom: 0.5em;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .fillintheblanks .fitbimage {position:relative; margin: 0 auto; text-align: center;}

  .correctanswertitle {margin-left: 1em;}

  .fillintheblanks {
    display: block;
    margin: 0;
    text-align: left;
    z-index:997;
  }

  .fillintheblanks select {
    width: 170px;
    margin: 0;
  }

  .fillintheblanks .blankanswer {
    background: none;
    border: none;
    margin: 0;
  }

  .fillintheblanks .questionask {line-height: @ax-line-height-l;}

  .fillintheblankcustomdrop {
    display: inline-block;
  }
  .fillintheblankcustomdrop .largedropdowncontainer {
    position: relative;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    outline: none;
    background-image: url("@/assets/questions/down-arrow.gif?_v12");
    background-position: 99% 50%;
    background-repeat: no-repeat;

    .question-preview-content & {
      cursor: default;
    }
  }

  .fillintheblankcustomdrop .largedropdowncontainer.incorrect span{
    text-decoration: line-through;
  }

  .fillintheblanks .correctforincorrect{
      vertical-align: middle;
  }

  .fillintheblankcustomdrop .selectorlist {
    position: absolute;
    left: 0;
    right: 0;
    border: none;
    list-style: none;
    overflow: hidden;
    z-index:998;
    display:none;
    text-align: left;
  }

  .fillintheblankcustomdrop .largedropdowncontainer .selectorlist ul {overflow:hidden;}
  .fillintheblankcustomdrop .largedropdowncontainer .selectorlist ul li {
    padding: 0.8em 0.3em;
    display: block;
    text-decoration: none;
  }

  .fillintheblankcustomdrop .largedropdowncontainer .selectorlist ul li.selected {
    background:@white;
  }

  .blankanswer .selectorlist {
    position: absolute;
    // NOTE: (line-height: .blankanswer button.selection-box) + ((line-height: .fillintheblanks .questionask) - (line-height: .blankanswer button.selection-box)/2)
    top: calc(@ax-line-height-m + (@ax-line-height-l - @ax-line-height-m)/2);
    left: 0;
    right: 0;
    border: 1px solid @primary-grey;
    list-style: none;
    overflow: hidden;
    z-index: 998;
    display: none;
    text-align: left;
  }

  .blankanswer .selectorlist ul {overflow:hidden;}
  .blankanswer .selectorlist li {
    padding: 0.8em 0.3em;
    display: block;
    text-decoration: none;
  }

  .blankanswer .selectorlist li.selected {
    background: @element-hover-bright;
  }

  .fillintheblanks .blankanswer.active{
    z-index:1000;
    .largedropdowncontainer .selectorlist {
      display: block;
    }
  }

  .blankanswer .selectorlist.active {
    display: block;
  }

  .question-preview-container {
    .fillintheblankcustomdrop .largedropdowncontainer {
      width: auto;
    }
    .answerwrapper { border: none; }
  }
  .fillintheblanks .blankanswer {
    position:relative;
    .largedropdowncontainer .selectorlist {
      display: none;
    }
  }

});

///
///
/// Question Preview Header Class
///
///
///

.question-preview-header {
  &.variant-details {
    .white-space-nowrap {
      white-space: nowrap;
    }
  }
}
/*============================= Retina image overrides ==============================*/

/*
  See these articles about proper use of screen resolution media queries:
    http://www.w3.org/blog/CSS/2012/06/14/unprefix-webkit-device-pixel-ratio/
    http://css-tricks.com/snippets/css/retina-display-media-query/
*/
@media
only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (                min-resolution: 124.8dpi),
only screen and (                min-resolution: 1.3dppx) {

  .hotspotimagewrapper .correct .check {
    background-image: url('@/assets/questions/correctcheck@2x.png');
    background-size:32px;
  }

  .hotspotimagewrapper .incorrect .check {
    background-image: url('@/assets/questions/incorrectcheck@2x.png');
    background-size:32px;
  }

  .answerletter .multicheck {
    background-image:url('@/assets/questions/multicheck@2x.gif');
    background-size: 32px;
  }

  .answerwrapper .answer .radiobutton {
    background-image:url('@/assets/questions/radio_select@2x.png');
    background-size: 32px;
  }

  .answerwrapper .selected.wrong .answertext {background-image:url('@/assets/questions/incorrectcheck@2x.png'); background-size: 32px;}
  .answerwrapper .correct .answertext {background-image:url('@/assets/questions/correctcheck@2x.png'); background-size: 32px;}
  .answerwrapper .incorrect .answertext,
  .answerwrapper .wrong .answertext {
    background-image:url('@/assets/questions/incorrectcheck@2x.png');
    background-size: 32px;
  }


}

button.zoom-image-wrap {
  .button-reset-style();

  &.focus-visible:focus {
    -webkit-box-shadow: 0px 0px 4px 2px @primary-blue;
    box-shadow: 0px 0px 4px 2px @primary-blue;
  }

  .screen-sm-max({
    min-width: initial;
    width: auto;
  });
}
